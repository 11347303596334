import React, { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import Spinner from 'react-bootstrap/Spinner';
import './App.css'
const Home = lazy(() => import('./pages/web/home/Home'))
const About = lazy(() => import('./pages/web/about/About'))
const Workshop = lazy(() => import('./pages/web/workshop/Workshop'))
const WorkshopDetails = lazy(() => import('./pages/web/workshop/Workshop_Details'))
const Contact = lazy(() => import('./pages/web/contact/Contact'))
const Experience = lazy(() => import('./pages/web/Experience'))
const Product = lazy(() => import('./pages/web/product/Product'))
const ProductDetails = lazy(() => import('./pages/web/product/Product_details'))
const Blog = lazy(() => import('./pages/web/blog/Blog'))
const BlogDetails = lazy(() => import('./pages/web/blog/Blog_details'))
const Service = lazy(() => import('./pages/web/services/Service'))
const ServiceDetails = lazy(() => import('./pages/web/services/Service_Details'))
const Cart = lazy(() => import('./pages/web/cart/Cart'))
const ExperienceDetails = lazy(() => import('./pages/web/experience/Experience_details'))
const Search = lazy(() => import('./pages/web/search/Search'))
const Checkout = lazy(() => import('./pages/web/checkout/Checkout'))
const Error404 = lazy(() => import('./pages/Eror404'))
const Terms = lazy(() => import('./pages/web/terms/Terms'))
const MiCuenta = lazy(() => import('./pages/web/account/MiCuenta'))
const ContenidoDetails = lazy(() => import('./pages/web/account/Contenido_Details'))
const OrderSuccess = lazy(() => import('./pages/web/cart/OrderSuccess'))
const RecuperarCuenta = lazy(() => import('./pages/web/checkout/RecuperarCuenta'))
const NewPass = lazy(() => import('./pages/web/NewPass'))

function App () {
  return (
    <div>
      <Suspense fallback={<div className='w-100 d-flex justify-content-center align-items-center align-content-center' style={{ height: '100vh', background: '#d9d9d9', columnGap:'10px' }}><Spinner animation="grow" variant="info" /><Spinner animation="grow" variant="info" /><Spinner animation="grow" variant="info" /></div>}>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/nosotros' element={<About />} />
          <Route path='/talleres' element={<Workshop />} />
          <Route path='/detalleres-del-taller' element={<WorkshopDetails />} />
          <Route path='/contactenos' element={<Contact />} />
          <Route path='/experience' element={<Experience />} />
          <Route path='/producto' element={<Product />} />
          <Route path='/detalles-del-producto' element={<ProductDetails />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/detalles-del-blog' element={<BlogDetails />} />
          <Route path='/servicios' element={<Service />} />
          <Route path='/detalleres-del-servicio' element={<ServiceDetails />} />
          <Route path='/detalles-de-la-experiencia' element={<ExperienceDetails />} />
          <Route path='/buscador' element={<Search />} />
          <Route path='/registro' element={<Checkout />} />
          <Route path='/carrito' element={<Cart />} />
          <Route path='*' element={<Error404 />} />
          <Route path='/terminos-y-condiciones' element={<Terms />} />
          <Route path='/cuenta' element={<MiCuenta />} />
          <Route path='/detalle-del-contenido' element={<ContenidoDetails />} />
          <Route path='/orden-exitosa' element={<OrderSuccess />} />
          <Route path='/recuperar-cuenta' element={<RecuperarCuenta />} />
          <Route path='/solicitar_nuevo_pass/clave' element={<NewPass />} />

        </Routes>
      </Suspense>

    </div>
  )
}

export default App
